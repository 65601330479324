/* Primary color */
/* #5f093d */
/* Secondary color */
/* #EAEAEA */

/* Global */
html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  background-color: #EAEAEA;
}

body {
  margin: 0;
  font-family: 'Literata', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  text-align: center;

  overflow-y: scroll;
  scroll-behavior: smooth;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.centered-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
}

.swiper-slide {
  width: 95%;
  height: 95%;

  display: flex !important;
  justify-content: center !important;
}

/* Home */
.home-component {
  scroll-snap-align: start;
}
.home-component::before {
  content: "";
  width: 100%;
  flex: 1;
}
.home-component::after {
  content: "";
  width: 100%;
  flex: 2;
}
#primary-logo {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
  width: 100%;
  max-width: 260px;
}
#primary-title {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
}
#secondary-logo {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  animation: fadein 1s;
  width: 100%;
  max-width: 260px;
}

/* Services */
.services-component {
  height: 100VH;
  width: 100%;
  scroll-snap-align: start;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-transform: uppercase;
}
#service-page-title {
  flex-grow: 1;
  z-index: 1;
  transform: translateY(10%);

  opacity: 85%;
  letter-spacing: 0.75em;
}
#service-options {
  flex-grow: 4;
  z-index: 1;

  letter-spacing: 0.1em;
}
#services-background-photo {
  position: absolute;
  animation: glideLeft 30.0s infinite;

  height: 95%;
  right: 0%;
  z-index: 0;

  opacity: 4%;
}

/* About */
.about-component {
  height: 100VH;
  width: 100%;
  scroll-snap-align: start;

  display: flex;
  flex-direction: column;
  justify-content: center;

  --swiper-navigation-sides-offset: 50px;
  --swiper-navigation-color: #5f093d;
}

/* Main images */
.main-images-track {
  width: 100%;
  height: 95%;
}
.main-images {
  height: 100%;
  width: 85%;
  object-fit: contain;
}

/* Contact */
.contact-component {
  height: 100VH;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-snap-align: start;
}
#contact-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 20px 0;
}
#contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  max-width: 30em;
}
.contact-form-submit-button {
  transform: translateY(75%);
}

/* Footer */
.footer-component {
  height: 20px;
}

/* Blog */
.blog-component {
  overflow-y: hidden;
}

/* Animations */

@keyframes glideLeft {
  from { transform: translateX(0); }
  to   { transform: translateX(-15%); }
}
@keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}
@keyframes fadeup {
  from { opacity: 0; transform: translateY(20px);}
  to   { opacity: 1; transform: translateY(0px);}
}

/* Firefox < 16 */
@-moz-keyframes glideLeft {
  from { transform: translateX(0); }
  to   { transform: translateX(-15%); }
}
@-moz-keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}
@-moz-keyframes fadeup {
  from { opacity: 0; transform: translateY(20px);}
  to   { opacity: 1; transform: translateY(0px);}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes glideLeft {
  from { transform: translateX(0); }
  to   { transform: translateX(-15%); }
}
@-webkit-keyframes fadein {
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0px);}
}
@-webkit-keyframes fadeup {
  from { opacity: 0; transform: translateY(20px);}
  to   { opacity: 1; transform: translateY(0px);}
}

/* Mobile */
@media screen and (max-width: 1000px) and (min-width: 200px) {
  html {
    --swiper-navigation-sides-offset: 10px;
  }
  .services-component {
    justify-content: space-around;
    align-items: center;
  }
  #service-page-title {
    flex-grow: 1;
    width: 100%;
    text-align: center;

    opacity: 80%;
    letter-spacing: 0.75em;
  }
  #services-background-photo {
    all: unset;
    width: 80VW;
    opacity: 40%;
    margin: 2em 0;
  }
  #service-options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 100%;
    letter-spacing: 0.1em;

    margin-left: 0;
    transform: translateX(0);
    width: 95%;
  }

  .about-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-images {
    height: 100%;
    width: 95%;
    object-fit: contain;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  html {
    --swiper-navigation-sides-offset: 10px;
  }
  .services-component {
    justify-content: space-around;
    align-items: center;
  }
  #service-page-title {
    flex-grow: 1;
    width: 100%;
    text-align: center;

    opacity: 80%;
    letter-spacing: 0.75em;
  }
  #services-background-photo {
    all: unset;
    width: 30VW;
    opacity: 40%;
    margin: 2em 0;
  }
  #service-options {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 100%;
    letter-spacing: 0.1em;

    margin-left: 0;
    transform: translateX(0);
    width: 95%;
  }

  .about-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-images {
    height: 100%;
    width: 95%;
    object-fit: contain;
  }
}

/* Very wide screens */
@media screen and (max-width: 3000px) and (min-width: 2000px) {
  .main-images {
    height: 100%;
    width: 60%;
    object-fit: cover;
  }
}